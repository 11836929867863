<template>
  <base-section id="pro-features">
    <base-section-heading :title="$t('pricing.title')"> </base-section-heading>
    <div style="padding-bottom: 16px; max-width: 840px; text-align: center; margin: auto; font-weight: 800; font-size: 1rem;">
      {{ $t("pricing.subTitle") }}
    </div>
    <v-container>
      <v-responsive class="mx-auto" max-width="1350" style="padding-bottom: 32px;">
        <v-container fluid>
          <v-row justify="center">
            <v-col
              v-for="card in cards"
              :key="card.title"
              cols="12"
              sm="4"
              md="3"
            >
              <base-info-card justify="center" dark v-bind="card" />
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
      <v-row justify="center">
        <v-col cols="12" lg="4" md="5" sm="6">
          <v-card class="mx-auto" flat style="max-width: 400px; border: 2px solid rgba(0, 0, 0, 0.15);">
            <v-card-title style="display: block">
              <v-row>
                <v-spacer /><span class="display-1">{{ $t("pricing.individual") }}</span
                ><v-spacer />
              </v-row>
              <v-row> <v-spacer />{{ $t("pricing.subscription") }}<v-spacer /> </v-row>
            </v-card-title>
            <v-card-text style="text-align: center">
              <span style="font-size: 24px">5€</span> / {{ $t("pricing.month") }}
              <p>{{ $t("pricing.taxesApply") }}</p>
            </v-card-text>
            <v-card-text>
              <v-icon color="primary"> mdi-check-bold </v-icon> {{ $t("pricing.fullFeatures") }}
              <v-divider style="margin-top: 8px; margin-bottom: 8px" />
              <v-icon color="primary"> mdi-check-bold </v-icon> {{ $t("pricing.fullFunctionality") }}
              <v-divider style="margin-top: 8px; margin-bottom: 8px" />
              <v-icon color="primary"> mdi-check-bold </v-icon> {{ $t("pricing.assistant") }}
              <v-divider style="margin-top: 8px; margin-bottom: 8px" />
              <v-icon color="primary"> mdi-check-bold </v-icon> {{ $t("pricing.allChannels") }}
              <v-divider style="margin-top: 8px; margin-bottom: 8px" />
              <v-icon color="primary"> mdi-check-bold </v-icon> {{ $t("pricing.updates") }}
              <v-divider style="margin-top: 8px; margin-bottom: 8px" />
              <v-icon color="primary"> mdi-check-bold </v-icon> {{ $t("pricing.trainingMaterials") }}
            </v-card-text>
            <v-card-actions style="padding: 16px">
              <v-spacer />
              <router-link :to="{ name: 'Contact' }">
                <base-btn> {{ $t("pricing.getStarted") }} </base-btn>
              </router-link>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" md="5" sm="6">
          <v-card class="mx-auto" flat style="max-width: 400px; border: 2px solid rgba(0, 0, 0, 0.15);">
            <v-card-title style="display: block">
              <v-row>
                <v-spacer /><span class="display-1">{{ $t("pricing.pro") }}</span><v-spacer />
              </v-row>
              <v-row> <v-spacer />{{ $t("pricing.subscription") }}<v-spacer /> </v-row>
            </v-card-title>
            <v-card-text style="text-align: center">
              <span style="font-size: 24px">15€</span> / {{ $t("pricing.month") }}
              <p>{{ $t("pricing.taxesApply") }}</p>
            </v-card-text>
            <v-card-text>
              <v-icon color="primary"> mdi-check-bold </v-icon> {{ $t("pricing.fullFeatures") }}
              <v-divider style="margin-top: 8px; margin-bottom: 8px" />
              <v-icon color="primary"> mdi-check-bold </v-icon> {{ $t("pricing.fullFunctionality") }}
              <v-divider style="margin-top: 8px; margin-bottom: 8px" />
              <v-icon color="primary"> mdi-check-bold </v-icon> {{ $t("pricing.assistant") }}
              <v-divider style="margin-top: 8px; margin-bottom: 8px" />
              <v-icon color="primary"> mdi-check-bold </v-icon> {{ $t("pricing.allChannels") }}
              <v-divider style="margin-top: 8px; margin-bottom: 8px" />
              <v-icon color="primary"> mdi-check-bold </v-icon> {{ $t("pricing.updates") }}
              <v-divider style="margin-top: 8px; margin-bottom: 8px" />
              <v-icon color="primary"> mdi-check-bold </v-icon> {{ $t("pricing.trainingMaterials") }}
              <v-divider style="margin-top: 8px; margin-bottom: 8px" />
              <v-icon color="primary"> mdi-check-bold </v-icon> {{ $t("pricing.supportTraining") }}
            </v-card-text>
            <v-card-actions style="padding: 16px">
              <v-spacer />
              <router-link :to="{ name: 'Contact' }">
                <base-btn> {{ $t("pricing.getStarted") }} </base-btn>
              </router-link>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionProChart",

  data: () => ({
    cards: [
      {
        icon: "mdi-credit-card-off-outline",
        title: "No Card Required",
        titleCode: "pricing.noCard",
        textCode: "pricing.noCardDescription",
        text: "Easy onboarding without inserting credit card information. Flexible payment menthods available.",
      },
      {
        icon: "mdi-weather-cloudy-clock",
        title: "30-Day Free Trial",
        titleCode: "pricing.30dayTrial",
        textCode: "pricing.30dayTrialDescription",
        text: "You can test-run free for 30 days which can be extended depending on your business case.",
      },
      {
        icon: "mdi-account-wrench-outline",
        title: "Setup & training",
        titleCode: "pricing.setupTraining",
        textCode: "pricing.setupTrainingDescription",
        text: "We help to set up your Tentifly business. Hourly fees apply (free with 6+ months of PRO package).",
      },
      {
        icon: "mdi-account-network-outline",
        title: "Promote & earn",
        titleCode: "pricing.promote",
        textCode: "pricing.promoteDescription",
        text: "For each new paying customer we gift 1 free month of use (Calculated quarterly).",
      },
    ],
  }),
};
</script>
